import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';

import '../../../src/styles/healthInfo.css';
import SelectedServer from './SelectedServer';

import { postACK } from '../../redux/actions/ackActions';
import FailoverIcon from '../../images/failover.png';
import CustomTooltipFailover from '../tooltips/CustomTooltipFailover';


const Server = ({ server, isAlert, isUnAck }) => {

  const [selectedServerName, setSelectedServerName] = useState('');  
  const [show, setShow] = useState(false);
  const [ack, setAck] = useState(server.serverAckStatus);

  const dispatch = useDispatch();
  const user = localStorage.getItem('email');

  const handleClose = () => {
    setShow(false);
    setSelectedServerName('');
  } 

  const handleShow = (serverName) => {
    setSelectedServerName(serverName);
    setShow(true);
  }

  const handleACK = (serverName) => {
    
    if (ack) {
      alert("This fault has already been acknowledged.");
    }
    else {
      const isConfirmed = window.confirm('Do you want to acknowledge this fault ?');

      if (isConfirmed) {
        const ackData = {serverName: serverName, user: user};
        dispatch(postACK(ackData));
        setAck(true);
      }
     
    }
  };

  const utcStrToLocal = (utcTimestamp) => {
    // Create a Date object using the UTC timestamp
    const utcDate = new Date(utcTimestamp);

    // Get the local time components
    const localYear = utcDate.getFullYear();
    const localMonth = utcDate.getMonth() + 1; // Months are zero-based
    const localDay = utcDate.getDate();
    const localHours = utcDate.getHours();
    const localMinutes = utcDate.getMinutes();
    const localSeconds = utcDate.getSeconds();
    // Create a new Date object with the local time components
    const localDate = new Date(localYear, localMonth - 1, localDay, localHours, localMinutes, localSeconds);
    // Format the local time as needed
    const formattedLocalTime = localDate.toLocaleString();
    return formattedLocalTime
  }

  // calculate total days since the give date time given have passed
  const daysSince = (targetDateStr) => {
    //const targetDate = parseCustomDateFormat(targetDateStr);    
    // Get the current local date and time
    let currentDate = new Date().toISOString();
    
    // Parse the date strings into Date objects
    currentDate = new Date(currentDate);
    let targetDate = new Date(targetDateStr);    

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - targetDate;     
   
    // Convert milliseconds to days
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);    
    return Math.floor(daysDifference);
  }

  let serverOfflineSince = server.serverOfflineSince;
  
  // server offline activity
  let offlineDays = 0;
  if (server.serverOfflineSince !== 'N/A') {
      serverOfflineSince = utcStrToLocal(server.serverOfflineSince);
      offlineDays = daysSince(server.serverOfflineSince);
  }
  else {
    serverOfflineSince = '-'
  }
  // server online activity
  let onlineDays = 1;
  if (server.serverStatus === 'Online') {
    onlineDays = server.serverOnlineDays;
  }

  // server activity time
  let activityDays = 0;
  if (server.serverStatus === 'Online') {
    activityDays = onlineDays;
  } else {
    activityDays = offlineDays;
  }


  let deviceColor = '#EF5B5B';
  if (server.devicesOffline === 0) {
    deviceColor = '#32de84';
  }

  let serverColor = '#EF5B5B';
  if (server.serverStatus === 'Online') {
    serverColor = '#32de84';
  }

  let activityColor = '#EF5B5B';
  if (server.serverStatus === 'Online') {
    activityColor = '#32de84';
  }

  const toolTipText = `Failover was enabled. All cameras were moved to ${server.backupServer} server.`;

//   useEffect(() => {
//     // load devices for the selected server from sql table
//     if (selectedServerName) {
//       dispatch(listDevices(selectedServerName, user));
//     localStorage.setItem('selectedServerName', selectedServerName); 
//     }    
//   }, [selectedServerName, dispatch, user]);

  
//   useEffect(() => {
//     if (user && selectedBusiness) {
//       dispatch(listServers({ user, selectedBusiness }));
//     }
   
//   }, [dispatch, user, ack, selectedBusiness]);

//  useEffect(() => {
//   if (server.serverAckStatus) {
//     setAck(false);
//   }
//  }, [server.serverAckStatus]);

  return (
    <>
      <tr>
          <td >{server.site}</td>
          <td >{server.si}</td>
          <td>{server.serverName}</td>          
          <td>
            <span style={{ color: serverColor }}>
              {server.serverStatus}
            </span>
          </td>
          <td>
          { server.serverStatus === 'Online' ? 
            <svg  style={{ color: '#32de84' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right-circle" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
            </svg> :
            <svg style={{ color: '#EF5B5B' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-right-circle" viewBox="0 0 16 16">
               <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.854 5.146a.5.5 0 1 0-.708.708L9.243 9.95H6.475a.5.5 0 1 0 0 1h3.975a.5.5 0 0 0 .5-.5V6.475a.5.5 0 1 0-1 0v2.768L5.854 5.146z"/>
          </svg>
          }
            <span style={{ color: activityColor, marginLeft: '.2rem' }}>{activityDays}d</span>
          </td>
          <td>{serverOfflineSince}</td>
          <td>
            <span style={{ width:'3.5em' }}>
              {server.totalDevices} / <span style={{ color: deviceColor}}>{server.devicesOffline}</span>
            </span>
            {/* {server.backupServer ? 
             <CustomTooltipFailover text={ toolTipText } >
              <span>
                <img alt='Failover' id='failover-icon' src={FailoverIcon}></img>
              </span> 
              </CustomTooltipFailover>
            : null
            } */}
          </td>
          <td>
            <div style={{ display: 'flex' , alignItems:'center', justifyContent:'space-between'}}>
              
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button className='alert-table-details-btn' disabled={!server.totalDevices} variant='dark'  onClick={() => handleShow(server.serverName)}>
                 Details
                </Button>
                {!isUnAck ? (
                isAlert ? (
               
                  <>
                  
                  { ack ? 
                    <input type="checkbox"  checked={true} style={{ marginLeft: '6em' }} onChange={() => handleACK(server.serverName)} /> : 
                    <div id='unack-chkbox-container'>
                    <input type="checkbox" className="custom-checkbox" checked={ack} onChange={() => handleACK(server.serverName)} />
                      <span style={{ marginLeft: '.4em' }}>Acknowledge</span>
                    </div>
                  }
                </>
                ) : null
                ): null}
              </div>
            </div> 
          </td>
      </tr>

      {/* modal to view device details */}

      <Modal size='lg' style={{ height: '50em'}} show={show} onHide={handleClose}>
        <Modal.Header closeButton closeVariant={'white'} style={{background: '#334066', height: '3em', paddingTop: '.2em' }} >
          <Modal.Title><span style={{ color:'white', fontSize: 'medium' }}>Cameras</span></Modal.Title>
          {/* <Button variant='text'  title="close" className='modal-close' onClick={() => handleClose()} ><span style={{ fontSize: '.8em', marginRight: '-1em' }}>X</span></Button> */}
        </Modal.Header>
        <Modal.Body style={{background: '#334066', paddingRight:0 }}>
        <SelectedServer  serverOfflineSince={serverOfflineSince} serverStatus={server.serverStatus}  serverName={selectedServerName} />
        </Modal.Body>
      </Modal>

    </>
  )
}

export default Server